/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  Course,
  Order,
  OrderItem,
  OrderType,
  AdjustmentType,
  RewardAdjustment,
  FeatureIDs,
  OrderStatus,
  OrderEvent,
  OrderAction,
  OrderPaymentStatus,
  OrderItemStatus,
} from '@oolio-group/domain';
import {
  limitDecimalCount,
  getAdjustmentValue,
  getPaymentSurchargeValueAndPaidAmount,
  getNetSubTotal,
  sumDecimals,
  ALL_ALLERGENS_KEY,
  getAdjustmentLabel,
} from '@oolio-group/order-helper';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { isNull, find } from 'lodash';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { useChangeDueReactiveVar } from '../../../hooks/app/useChangeDueReactiveVar';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { disableTokenNumberForOrderType } from '../../../utils/tokenNumber';
import {
  orderTotalItemsCount,
  canPerformSaveAction,
} from '../../../utils/cart';
import theme from '../../../common/default-theme';
import styles from './Cart.styles';
import CartItems from './CartItems/CartItems';
import { CartOrderTotalItems } from './CartItems/CartTotalItems';
import ChangeDue from './CartTotals/ChangeDue';
import CartKeypad from './CartKeypad/CartKeypad';
import CartTotals from './CartTotals/CartTotals';
import CartHeader from './CartHeader/CartHeader';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import ConfirmationModal from '../../Modals/ConfirmationDialog';
import CartActions, { CartAction } from './CartActions/CartActions';

export interface SelectedComboOptions {
  parentModifierGroupId?: string;
  comboProductId?: string;
  childModifierGroupId?: string;
  selectedComboKey?: string;
  selectedVariantId?: string;
}

export type CartSelectionState = {
  item: string;
  quantity?: number;
  modifier?: string;
  variant?: string;
  product?: string;
  modifierGroup?: string;
  selectedVariantKey?: string;
  selectedModifierKey?: string;
  selectedItemKeyForAdjustment?: string;
  combo?: SelectedComboOptions;
};

export type CartRewardSelectionState = {
  reward: string;
  productId?: string;
};

export interface CartProps {
  order: Order;
  orderType: OrderType;
  onPressChangeOrderType: () => void;
  onPressTableNumber: () => void;
  onIncrementSeatNumber: () => void;
  setSelectedSeatNumber: (number?: string) => void;
  orderItems: OrderItem[];
  courses?: Course[];
  onToggleAutoFire?: (courseId: string) => void;
  onSelectCartItem: (state: CartSelectionState) => void;
  selectedCartItem: CartSelectionState | undefined;
  isAdvancedKeypad: boolean;
  disableCartActions: boolean;
  isOrderComplete: boolean;
  onPressDangerAction: (quantityToBeKept?: number | undefined) => void;
  onPressUpdatePrice: () => void;
  onDeselectCartItem: () => void;
  onPressUpdateQuantity: () => void;
  splitProductFromCart: () => void;
  onPressAction: (action: string) => void;
  onPressSplitProductFromCart: () => void;
  advancedKeypadValue: number;
  setAdvancedKeypadValue: (value: number) => void;
  onPressPay: () => void;
  disableOrderActions: boolean;
  isDirty: boolean;
  onPressNewOrder: () => void;
  onPressSave: () => void;
  disableSaveAction: boolean;
  enableQuickPaymentMode: boolean;
  navigateToRefundScreen?: () => void;
  onSwitchCourseItem?: (
    orderItemId: string,
    courseId: string,
    courseName?: string,
  ) => void;
  onPressCompleteOrder?: () => void;
  onSelectReward?: (state: CartRewardSelectionState) => void;
  onShowAllergens?: () => void;
  selectedReward?: CartRewardSelectionState;
  selectedSeatNumber?: string;
  updateCart: <T extends OrderEvent>(
    action: OrderAction,
    input?: Omit<T, keyof OrderEvent>,
    eventId?: string,
  ) => void;
}

const Cart: React.FC<CartProps> = ({
  order,
  orderType,
  onPressChangeOrderType,
  onPressTableNumber,
  orderItems,
  onSelectCartItem,
  selectedCartItem,
  isAdvancedKeypad,
  disableCartActions,
  isOrderComplete,
  onPressDangerAction,
  onPressUpdatePrice,
  onPressUpdateQuantity,
  splitProductFromCart,
  onPressAction,
  onPressSplitProductFromCart,
  advancedKeypadValue,
  setAdvancedKeypadValue,
  onPressPay,
  disableOrderActions,
  onPressSave,
  disableSaveAction,
  onDeselectCartItem,
  enableQuickPaymentMode,
  navigateToRefundScreen,
  isDirty,
  onPressNewOrder,
  courses,
  onToggleAutoFire,
  onIncrementSeatNumber,
  setSelectedSeatNumber,
  onSwitchCourseItem,
  onPressCompleteOrder,
  onSelectReward,
  onShowAllergens,
  selectedReward,
  selectedSeatNumber,
  updateCart,
}) => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const changeDue = useChangeDueReactiveVar();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();

  const orderAllergens =
    order?.allergens?.[selectedSeatNumber || ALL_ALLERGENS_KEY] ?? [];

  const isCourseFeatureEnabledAtVenue = isFeatureEnabled(
    FeatureIDs.COURSES,
    session.currentVenue?.id,
  );

  const isAllergensFeatureEnabled = isFeatureEnabled(
    FeatureIDs.ALLERGENS,
    session.currentVenue?.id,
  );

  const isCoursesEnabled =
    isCourseFeatureEnabledAtVenue && session?.deviceProfile?.enableCourses;

  const isAllergensEnabled =
    isAllergensFeatureEnabled && session?.deviceProfile?.enableAllergens;

  const rewardItems = (order?.adjustments || []).filter(
    adj => adj.adjustmentType == AdjustmentType.REWARD,
  ) as RewardAdjustment[];

  const isNegativePrice = useMemo(() => {
    if (!selectedCartItem) {
      return false;
    }
    const selectedOrderItem = find(orderItems, { id: selectedCartItem.item });
    if (!selectedOrderItem) {
      return false;
    }
    if (selectedCartItem.modifier) {
      const selectedModifierItem = find(selectedOrderItem.modifiers, {
        id: selectedCartItem.modifier,
      });
      if (selectedModifierItem) {
        return selectedModifierItem.unitPrice < 0;
      } else {
        return false;
      }
    } else {
      return selectedOrderItem.unitPrice < 0;
    }
  }, [selectedCartItem, orderItems]);

  const isOrderOnHold = order?.status === OrderStatus.ON_HOLD;
  const isCartDisplayEmpty = !Boolean(orderItems.length || order?.orderNote);

  const finalizeOrder = useCallback(async () => {
    if (order) {
      updateCart(OrderAction.REMOVE_ORDER_HOLD);
      await updateCart(OrderAction.ORDER_SAVE);
      showNotification({
        success: true,
        message: translate('order.holdOrderFinaliseSuccess'),
      });
      // after finalizing we navigate to new order screen
      onPressNewOrder();
      closeModal();
    }
  }, [
    order,
    updateCart,
    closeModal,
    onPressNewOrder,
    showNotification,
    translate,
  ]);

  const showFinaliseOrderWarning = useCallback(async () => {
    showModal(
      <ConfirmationModal
        type="negative"
        title={translate('order.holdOrderModalTitle')}
        message={translate('order.holdOrderModalDesc', {
          count: orderTotalItemsCount(order),
        })}
        onConfirm={finalizeOrder}
        confirmLabel={translate('cart.finalise')}
        onCancel={closeModal}
      />,
    );
  }, [showModal, order, translate, closeModal, finalizeOrder]);

  const PrimaryCartButton = useMemo(() => {
    let button = (
      <TreatButton
        testID="btn-pay"
        uppercase
        height={60}
        type="positive"
        label={translate('tableFloorView.pay')}
        disabled={disableOrderActions || (order?.amountDue || 0) < 0}
        onPress={onPressPay}
        containerStyle={styles.btnCartPrimary}
      />
    );

    const isOrderOnHoldAndNoAmountDue =
      order?.amountDue === 0 && order.payments?.length && isOrderOnHold;
    const isOrderWithNoAmountDue =
      order?.amountDue === 0 && order.payments?.length;
    const isOrderWithoutItemsAndNoAmountDue =
      order?.amountDue != undefined &&
      order.amountDue <= 0 &&
      order.orderItems?.filter(
        item =>
          item.status !== OrderItemStatus.CANCELLED &&
          item.status !== OrderItemStatus.TRANSFERRED,
      )?.length === 0;

    if (isOrderOnHoldAndNoAmountDue) {
      button = (
        <TreatButton
          testID="finalize-button"
          uppercase
          height={60}
          type="neutral"
          label={translate('cart.finalise')}
          disabled={disableOrderActions}
          onPress={showFinaliseOrderWarning}
          containerStyle={styles.btnCartPrimary}
        />
      );
    } else if (isOrderWithNoAmountDue || isOrderWithoutItemsAndNoAmountDue) {
      button = (
        <TreatButton
          testID="complete-now-button"
          height={60}
          uppercase
          type="positive"
          label={translate('button.complete')}
          disabled={disableOrderActions}
          onPress={() => onPressCompleteOrder && onPressCompleteOrder()}
          containerStyle={styles.btnCartPrimary}
        />
      );
    }

    return button;
  }, [
    disableOrderActions,
    onPressCompleteOrder,
    onPressPay,
    order,
    translate,
    isOrderOnHold,
    showFinaliseOrderWarning,
  ]);

  const orderIdentifier = useMemo(() => {
    if (order?.orderName) {
      return order.orderName;
    } else if (disableTokenNumberForOrderType(orderType)) {
      // Do not show token number if order type is delivery or dine in
      return '';
    } else if (order?.tokenNumber && Number.isInteger(order?.tokenNumber)) {
      return order.tokenNumber?.toString();
    }
  }, [order?.orderName, order?.tokenNumber, orderType]);

  // if cart is marked as dirty, add save action on the order
  // if cart has to send the order to kitchen
  //    i.e.,
  //      if cart has some items which are not sent to kitchen
  //      and had some payment attempts(for card payment cases)
  //      and no payment is in pending state
  //      then, add save action on the order
  const saveBtnAction =
    isDirty || canPerformSaveAction(order) ? onPressSave : onPressNewOrder;

  const CartActionsList = useMemo((): CartAction[] => {
    return [
      {
        testID: 'btn-remove',
        icon: 'trash-alt',
        iconColor: theme.colors.states.negative,
        disabled: disableCartActions || isOrderComplete,
        action: () => onPressDangerAction(),
      },
      {
        testID: 'btn-price',
        icon: 'usd-circle',
        iconColor: theme.colors.states.positive,
        disabled:
          disableCartActions ||
          selectedCartItem?.modifier !== undefined ||
          isOrderComplete,
        action: onPressUpdatePrice,
      },
      {
        testID: 'btn-quantity',
        icon: 'exposure-increase',
        iconColor: theme.colors.states.neutral,
        disabled: !selectedCartItem || isOrderComplete,
        action: onPressUpdateQuantity,
      },
      {
        testID: 'btn-split',
        icon: 'align-center-h',
        iconColor: theme.colors.states.focus,
        disabled:
          !selectedCartItem ||
          isOrderComplete ||
          selectedCartItem.quantity === 1,
        action: splitProductFromCart,
      },
    ];
  }, [
    disableCartActions,
    isOrderComplete,
    onPressDangerAction,
    onPressUpdatePrice,
    onPressUpdateQuantity,
    selectedCartItem,
    splitProductFromCart,
  ]);

  const getOrderTotalItems = (): CartOrderTotalItems => {
    const subTotal = order?.subTotal || 0;
    const subTotalAfterAdjustment = getNetSubTotal(
      subTotal,
      order?.adjustments,
    );

    const processAdjustments = (adjType: AdjustmentType) => {
      return (
        order?.adjustments
          ?.filter(
            adj =>
              adj.adjustmentType === adjType &&
              (adjType !== AdjustmentType.SURCHARGE || !adj.allowOnPaymentType),
          )
          .map(adj => {
            const adjustmentName = getAdjustmentLabel(adj);

            return {
              name: adjustmentName,
              amount: limitDecimalCount(
                getAdjustmentValue(
                  adj.doNotIncludeInSalesAmount
                    ? subTotalAfterAdjustment
                    : subTotal,
                  [adj],
                ),
              ),
            };
          }) || []
      );
    };

    const discounts = processAdjustments(AdjustmentType.DISCOUNT);
    const surcharges = processAdjustments(AdjustmentType.SURCHARGE);

    const { paymentSurcharge } = getPaymentSurchargeValueAndPaidAmount(
      order?.payments ?? [],
    );

    const payments = order?.payments?.filter(
      payment => payment.status === OrderPaymentStatus.COMPLETE,
    );

    const totalPaid = payments
      ? sumDecimals(payments.map(payment => payment.amount))
      : undefined;

    return {
      subTotal: order?.subTotal,
      rewards: order?.rewardDiscountAmount,
      discounts,
      surcharges,
      paymentSurcharge: order?.adjustments?.some(
        surcharge => surcharge.allowOnPaymentType,
      )
        ? paymentSurcharge
        : undefined,
      tip: order?.tip,
      deliveryFee: order?.deliveryFee,
      serviceCharge: order?.serviceCharge,
      taxes: order?.taxes,
      orderTotal: order?.totalPaymentAmount,
      payments: totalPaid,
    };
  };

  return (
    <View testID="cart" style={styles.container}>
      <CartHeader
        orderNumber={order?.orderNumber || ''}
        orderType={orderType}
        tableNumber={order?.table?.name}
        allergens={orderAllergens}
        isAllergensEnabled={isAllergensEnabled}
        onShowAllergens={onShowAllergens}
        onSelectOrderType={onPressChangeOrderType}
        onSelectTableNumber={onPressTableNumber}
      />
      <View style={styles.cart}>
        {isOrderOnHold ? (
          <View style={styles.onHoldContainer}>
            <Text style={styles.onHoldText}>
              {translate('cart.printOnHold') +
                ` (${orderTotalItemsCount(order)})`}
            </Text>
          </View>
        ) : null}
        <CartItems
          orderGuestCount={order?.table?.guestCount}
          items={orderItems}
          rewardItems={rewardItems}
          courses={courses}
          onToggleAutoFire={onToggleAutoFire}
          onSelectItem={onSelectCartItem}
          onDeselectItem={onDeselectCartItem}
          onIncrementSeatNumber={onIncrementSeatNumber}
          setSelectedSeatNumber={setSelectedSeatNumber}
          orderNote={order?.orderNote}
          orderIdentifier={orderIdentifier}
          selectedItem={selectedCartItem}
          scrollToBottom
          onSwitchCourseItem={onSwitchCourseItem}
          isCoursesEnabled={isCoursesEnabled}
          orderType={orderType}
          subTotal={order?.subTotal}
          isTraining={order?.isTraining}
          onSelectReward={onSelectReward}
          selectedReward={selectedReward}
          selectedSeatNumber={selectedSeatNumber}
          orderTotals={getOrderTotalItems()}
        />
      </View>
      <View
        style={{
          rowGap: 4,
          flexDirection: isAdvancedKeypad ? 'column-reverse' : 'column',
        }}
      >
        {isAdvancedKeypad ? (
          <CartKeypad
            onPressAction={onPressAction}
            onPressSplitProductFromCart={onPressSplitProductFromCart}
            cancelActionDisabled={disableCartActions || isOrderComplete}
            updatePriceActionDisabled={
              disableCartActions ||
              selectedCartItem?.modifier !== undefined ||
              isOrderComplete
            }
            addActionDisable={
              !selectedCartItem || isOrderComplete || isNegativePrice
            }
            subtractActionDisabled={!selectedCartItem || isOrderComplete}
            splitProductDisabled={!selectedCartItem || isOrderComplete}
            keypadInput={
              advancedKeypadValue ? advancedKeypadValue.toString() : ''
            }
            setKeypadInput={setAdvancedKeypadValue}
          />
        ) : (
          <CartActions actions={CartActionsList} />
        )}
        {enableQuickPaymentMode && !isNull(changeDue) ? (
          <ChangeDue change={changeDue} />
        ) : !Boolean(isCartDisplayEmpty) ? (
          <CartTotals minimised order={order} />
        ) : null}
      </View>
      <View style={styles.cartButtons}>
        {isOrderComplete && (
          <TreatButton
            testID="btn-refund"
            type="focus"
            label="Refund Order"
            onPress={() => navigateToRefundScreen && navigateToRefundScreen()}
            containerStyle={{ flex: 1 }}
          />
        )}
        {!isOrderComplete && (
          <>
            {PrimaryCartButton}
            {!session?.settings?.hideSendButton ? (
              <TreatButton
                testID="btn-send"
                uppercase
                height={60}
                type="neutral"
                label={translate('button.send')}
                disabled={disableSaveAction}
                onPress={saveBtnAction}
                containerStyle={{ flex: 1, marginLeft: 4 }}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </View>
    </View>
  );
};

export default Cart;
