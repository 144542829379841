import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { format } from 'date-fns';
import { OrderStatus, OrderTypeCode } from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { OrderHistoryItem } from '../../../POS/Orders/OpenOrders/OpenOrders.types';
import theme from '../../../../common/default-theme';
import styles from './OrderHistoryTable.styles';
import Icon from '../../../../components/Icon/Icon';
import Identifier from '../../../../components/Shared/Identifier/Identifier';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import PrintReceiptButton from '../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';

interface Props {
  order: OrderHistoryItem;
  onPress: () => void;
  onPressSendReceipt: (order: OrderHistoryItem) => void;
  onPressReprintDocket: (orderId: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
}

const OrderHistoryRow: React.FC<Props> = ({
  order,
  onPress,
  onPressSendReceipt,
  onPressPrintReceipt,
  onPressReprintDocket,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const getOrderName = (): string => {
    const { orderType, table, customerName, tokenNumber } = order;
    const hasCustomer = order.customerName !== 'N/A';

    const tableName = table?.name
      ? `${translate('reservations.table')} ${table.name}`
      : '';

    switch (orderType.code as OrderTypeCode) {
      case OrderTypeCode.DINE_IN:
        return `${tableName}${hasCustomer ? ` (${customerName})` : ''}`;
      case OrderTypeCode.TAKEAWAY:
        return hasCustomer
          ? `${
              tokenNumber ? `#${tokenNumber} (${customerName})` : customerName
            }`
          : tokenNumber
          ? `#${tokenNumber}`
          : 'N/A';
      default:
        return customerName;
    }
  };

  const getPaymentsLabel = (): string => {
    const first = order.payTypes[0];
    const label = `${first.name} (${formatCurrency(first.amount)})`;

    if (order.payTypes.length > 1) {
      return `${label} + ${order.payTypes.length - 1} more`;
    } else {
      return label;
    }
  };

  const isRefund = Boolean(order.refundOf);
  const orderNo = order.orderNumber.substring(0, order.orderNumber.length - 9);
  const orderName = getOrderName();
  const orderAmt = `${isRefund ? '-' : ''}${formatCurrency(
    Math.abs(order.totalValue),
  )}`;

  const textColor = isRefund
    ? theme.colors.states.negative
    : theme.colors.black1;

  return (
    <TouchableOpacity
      testID="row-openOrder"
      onPress={onPress}
      style={[styles.tableRow, order.isTraining && styles.tableRowTraining]}
    >
      <Identifier orderType={order.orderType.code as OrderTypeCode} />
      <Text
        numberOfLines={1}
        testID="order-completedAt"
        style={styles.cellDate}
      >
        {format(order.completedAt, 'MMM dd, hh:mm a')}
      </Text>
      <Text
        testID="order-no"
        numberOfLines={1}
        style={[styles.cellNo, { color: textColor }]}
      >
        {orderNo}
      </Text>
      <Text
        testID="order-name"
        numberOfLines={1}
        style={[
          styles.cellName,
          {
            color:
              orderName !== 'N/A' ? theme.colors.black1 : theme.colors.grey5,
          },
        ]}
      >
        {getOrderName()}
      </Text>
      <Text
        testID="order-payments"
        numberOfLines={1}
        style={styles.cellPayments}
      >
        {getPaymentsLabel()}
      </Text>
      <Text
        testID="order-amount"
        style={[styles.cellAmt, { color: textColor }]}
      >
        {orderAmt}
      </Text>
      <PrintReceiptButton
        options={order.payTypes}
        onPrintReceipt={paymentIndex =>
          onPressPrintReceipt(order.id, paymentIndex)
        }
        reprintDocket={() => {
          onPressReprintDocket(order.id);
        }}
        orderStatus={OrderStatus.COMPLETED}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginLeft: 20, marginRight: 10 }}
      />
      <ButtonIcon
        testID="btn-send"
        icon="envelope"
        type="neutralLight"
        onPress={() => onPressSendReceipt(order)}
      />
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export default OrderHistoryRow;
